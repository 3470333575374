import React, { Fragment, Component } from "react";
import WOW from "wowjs";
import { ProDetail } from "./Data";
import Detail3Owl from "./Detail3Owl";
import NicoleCorner from "./../../../images/ium-temp-images/nicole-corner.jpg";
import Header2 from "../../Layout/Header2";

const detailBlog = [
  {
    icon: <i className="ti ti-user" />,
    title: "ARCHITECT",
    subtitle: "Tyber Construction",
  },
  {
    icon: <i className="ti ti-location-pin" />,
    title: "LOCATION",
    subtitle: "Sandton, South Africa",
  },
  {
    icon: <i className="ti ti-ruler-alt-2" />,
    title: "SIZE",
    subtitle: "4 500 sqm",
  },
  {
    icon: <i className="ti ti-home" />,
    title: "TYPE",
    subtitle: "Commercial",
  },
];

const latestBlog = [
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/audi-sandton/Audi Sandton - 1.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/audi-sandton/Audi Sandton - 2.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/audi-sandton/Audi Sandton - 3.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/audi-sandton/Audi Sandton - 4.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/audi-sandton/Audi Sandton - 5.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/audi-sandton/Audi Sandton - 6.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/audi-sandton/Audi Sandton - 7.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/audi-sandton/Audi Sandton - 8.jpg",
  },
];
class NicolCorner extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  render() {
    return (
      <Fragment>
        <Header2 />
        <div className="page-content bg-white">
          {/*  banner  */}
          <div
            className="banner-three dlab-bnr-inr"
            style={{
              backgroundImage:
                "url(https://cdn.uberpd.co.za/img/projects/audi-sandton/Header.jpg)",
              backgroundSize: "cover",
              backgroundPosition: 'center 45%'
            }}
            data-content="HOME"
            id="sidenav_home"
          ></div>

          <div
            className="section-full content-inner-2 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div
              className="container-fluid"
              style={{ maxWidth: "1700px", margin: "auto" }}
            >
              <Detail3Owl latestBlog={latestBlog} />
            </div>
          </div>
          {/*  Info Carousel */}

          {/*  Project Info  */}
          <section className="section-full content-inner">
            <div className="container">
              <div className="section-head">
                <span>DEVELOPED 2015</span>
                <h4 className="title">Audi Sandton</h4>
              </div>
              <p>
                Located on the corner of Katherine Road and Grayston Drive, this
                property sits on a 7 200 sqm stand and offers 4 500 sqm of
                versatile space.
              </p>

              <p>
                <b>Property Overview</b>
              </p>
              <p>
                The building is designed to accommodate various commercial
                functions, including:
              </p>
              <p
                style={{
                  listStyleType: "disc",
                  marginLeft: "3%",
                  marginTop: "3%",
                }}
              >
                <b>Showroom:</b> A spacious and elegantly designed showroom,
                perfect for displaying luxury vehicles in a premium, high-end
                setting, offering customers an immersive experience with the
                latest models.
              </p>
              <p
                style={{ listStyleType: "disc", marginLeft: "3%" }}
                className="m-b30"
              >
                <b>Workshop:</b> A space designed for precision maintenance and
                repairs, ensuring the highest standards of care for
                high-performance vehicles and luxury cars.
              </p>
              <p
                style={{ listStyleType: "disc", marginLeft: "3%" }}
                className="m-b30"
              >
                <b>Office and Admin Space:</b> Sophisticated and well-appointed
                offices, designed to enhance operational efficiency and
                collaboration, providing a seamless environment for both sales
                and administrative teams.
              </p>

              <p>
                <b>Location and Accessibility</b>
              </p>

              <p>
                Strategically located in the heart of Sandton, the property
                provides unmatched accessibility to key commercial hubs, making
                it a premium choice for businesses looking to establish a
                foothold in Johannesburg's vibrant business district.
              </p>

              <div className="row m-b30 widget widget_getintuch widget_getintuch-pro-details pro-details-col-5 m-lr0">
                <ProDetail detailBlog={detailBlog} />
              </div>
            </div>
          </section>
          {/*  Project Info  End*/}
        </div>
      </Fragment>
    );
  }
}

export default NicolCorner;
