import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Index1 from "./Pages/Homepage/Index1";

import ErrorPage from "./Pages/ErrorPage";
import ComingSoon from "./Pages/ComingSoon";

import ContactUs1 from "./Pages/Contact/ContactUs1";
import MontBlancHeight from "./Pages/ProjectDetail/mont-blanc-height";
import MontSaintMichel from "./Pages/ProjectDetail/mont-saint-michel";
import BradfordRoad from "./Pages/ProjectDetail/bradford-road";
import ArdenwoldManor from "./Pages/ProjectDetail/ardenwold-manor";
import NicolCorner from "./Pages/ProjectDetail/alpha-building";
import HydePark from "./Pages/ProjectDetail/hyde-park-corner";
import RidgeRoad from "./Pages/ProjectDetail/ridge-road";
import BradfordRoad2 from "./Pages/ProjectDetail/bradford-road-2";
import AudiSandton from "./Pages/ProjectDetail/audi-sandton";
import ScrollToTop from "./Element/ScrollToTop";

class Markup extends Component {
  render() {
    const ComingSoon = () => {
      return <Redirect to="/" />;
    };

    return (
      <BrowserRouter basename="/">
        <div className="page-wraper">
          <Switch>
            <Route path="/" exact component={Index1} />

            <Route path="/404-page" exact component={ErrorPage} />
            <Route path="/coming-soon" exact component={ComingSoon} />

            <Route
              path="/mont-blanc-height"
              exact
              component={MontBlancHeight}
            />

            <Route
              path="/mont-saint-michel"
              exact
              component={MontSaintMichel}
            />
            <Route path="/ardenwold-manor" exact component={ArdenwoldManor} />
            <Route path="/bradford-road" exact component={BradfordRoad} />
            <Route path="/bradford-road-2" exact component={BradfordRoad2} />

            <Route path="/alpha-building" exact component={NicolCorner} />
            <Route path="/hyde-park-corner" exact component={HydePark} />
            <Route path="/ridge-road" exact component={RidgeRoad} />
            <Route path="/audi-sandton" exact component={AudiSandton} />
            <Route path="/contact-us" exact component={ContactUs1} />
          </Switch>
        </div>
        <ScrollToTop />
      </BrowserRouter>
    );
  }
}

export default Markup;
