import React, { Fragment, Component } from "react";
import WOW from "wowjs";
import { ProDetail } from "./Data";
import Detail3Owl from "./Detail3Owl";
import NicoleCorner from "./../../../images/ium-temp-images/nicole-corner.jpg";
import Header2 from "../../Layout/Header2";

const detailBlog = [
  {
    icon: <i className="ti ti-user" />,
    title: "ARCHITECT",
    subtitle: "Cimato Moroldo",
  },
  {
    icon: <i className="ti ti-location-pin" />,
    title: "LOCATION",
    subtitle: "Bedfordview, South Africa",
  },
  {
    icon: <i className="ti ti-ruler-alt-2" />,
    title: "SIZE",
    subtitle: "22 000 sqm",
  },
  {
    icon: <i className="ti ti-home" />,
    title: "TYPE",
    subtitle: "Commercial",
  },
];

const latestBlog = [
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (1).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (2).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (3).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (4).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (5).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (6).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (7).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (8).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (9).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (10).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (11).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (12).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (13).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (14).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (15).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (16).JPG",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (17).jpg",
  },
];
class NicolCorner extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  render() {
    return (
      <Fragment>
        <Header2 />
        <div className="page-content bg-white">
          {/*  banner  */}
          <div
            className="banner-three dlab-bnr-inr"
            style={{
              backgroundImage: "url(" + NicoleCorner + ")",
              backgroundSize: "cover",
            }}
            data-content="HOME"
            id="sidenav_home"
          ></div>

          <div
            className="section-full content-inner-2 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div
              className="container-fluid"
              style={{ maxWidth: "1700px", margin: "auto" }}
            >
              <Detail3Owl latestBlog={latestBlog} />
            </div>
          </div>
          {/*  Info Carousel */}

          {/*  Project Info  */}
          <section className="section-full content-inner">
            <div className="container">
              <div className="section-head">
                <span>DEVELOPED 2021</span>
                <h4 className="title">Alpha Buidling</h4>
                <button
                  style={{
                    backgroundColor: "#0d8a45",
                    border: "none",
                    borderRadius: "5px",
                    padding: "10px 20px",
                    fontWeight: "bold",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="https://viewer.joomag.com/architect-and-builder-may-2022/0902988001653567288/p54?s"
                    target="_blank"
                    style={{
                      color: "#ffffff",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    Explore Alpha Buidling's feature in the Architect and
                    Builder Magazine
                  </a>
                </button>
              </div>
              <p>
                Located at the corner of Nicol and Bradford Road, Bedfordview,
                our new 11-storey, high-rise office building serves as the Alpha
                Insure head office. This state-of-the-art facility features a
                gym, extensive office space, a rooftop terrace, and basement
                parking.
              </p>
              <p>
                Careful thought and consideration have gone into creating the
                iconic design of the new Alpha Insure building.
              </p>
              <p>
                Alpha Insure is the largest independently owned Cell Captive UMA
                in Southern Africa, underwritten by Guardrisk Insurance Company
                Ltd. Our innovative approach to risk and superior service
                offering yields tangible benefits to clients, intermediaries,
                employees, and stakeholders, ensuring transparency in our
                corporate governance practices. We provide market-leading
                insurance solutions for multinational corporations, local
                businesses, and individuals.
              </p>
              <p>
                <b>Property Overview</b>
              </p>
              <p>
                The building incorporates advanced solutions such as Frit-dot
                Glazing. This is the first building in Africa with an entire
                glazed façade specified with Frit-dot system glazing panels.
                These enamelled dots provide solar control, shading, decoration,
                and privacy.
              </p>
              <p>
                The air conditioning system uses Mitsubishi’s heat recovery
                variable refrigerant flow system, one of the most efficient HVAC
                systems available today.
              </p>
              <p>
                <b>Additional features</b>
              </p>
              <p
                className="m-b30"
                style={{ listStyleType: "disc", marginLeft: "3%" }}
              >
                An array of solar panels supplementing grid-fed power
              </p>
              <p
                className="m-b30"
                style={{ listStyleType: "disc", marginLeft: "3%" }}
              >
                Energy-efficient water heating and rainwater harvesting systems
              </p>
              <p
                className="m-b30"
                style={{ listStyleType: "disc", marginLeft: "3%" }}
              >
                Vertical landscaping at various levels
              </p>
              <p>
                The interior design mirrors the cutting-edge exterior
                architecture. With triple-A grade finishes throughout, the
                workspaces exude luxury and artistry, creating an environment
                conducive to productivity and spaciousness. This luxurious and
                relaxing work environment spares no expense.
              </p>
              <p>
                The building also houses Africa’s first 6-star gym, an exclusive
                fitness centre featuring the latest training technology and
                equipment. Spanning 5 000 sqm and accommodating up to 5 000
                members, the gym includes childcare facilities, spinning and
                Pilates studios, a fully equipped boxing training area, an
                external training area, and a sprinting track.
              </p>

              <div className="row m-b30 widget widget_getintuch widget_getintuch-pro-details pro-details-col-5 m-lr0">
                <ProDetail detailBlog={detailBlog} />
              </div>
            </div>
          </section>
          {/*  Project Info  End*/}
        </div>
      </Fragment>
    );
  }
}

export default NicolCorner;
