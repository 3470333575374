import React, {
  Fragment,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";

import Header2 from "./../../Layout/Header2";

import { ExhibitionSection } from "./../CompanyExhibition";

import Slider1 from "./../../Element/Slider1";
import Modal from "../../../components/modal/modal";

//modules

const Index1 = () => {
  const [showModal, setShowModal] = useState(true);
  const refAnimationInstance = useRef(null);
  const [intervalId, setIntervalId] = useState();
  function getAnimationSettings(angle, originX) {
    return {
      particleCount: 3,
      angle,
      spread: 55,
      origin: { x: originX },
      colors: ["#de7e49", "#50251e", "#ca5c2c"],
    };
  }

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);
  const [currentProfile, setCurrentProfile] = useState(null);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(60, 0));
      refAnimationInstance.current(getAnimationSettings(120, 1));
    }
  }, []);

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(setInterval(nextTickAnimation, 16));
    }
  }, [nextTickAnimation, intervalId]);

  const pauseAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
  }, [intervalId]);

  const stopAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
    refAnimationInstance.current && refAnimationInstance.current.reset();
  }, [intervalId]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  return (
    <>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setTimeout(() => {
            startAnimation();
          }, 100);
          setTimeout(() => {
            pauseAnimation();
            pauseAnimation();
          }, 6000);
        }}
        onClosed={() => {
          setCurrentProfile(null);
        }}
        Page="Home"
      >
        <></>
      </Modal>

      {
        <Fragment>
          <Header2 />
          {/* <!-- Main Slider --> */}
          <div
            className="page-content bg-white"
            style={{ textAlign: "justify" }}
          >
            <div
              className="banner-three homepagebanner"
              style={{
                backgroundImage:
                  "url(https://cdn.uberpd.co.za/img/homepage/homepageheader.jpg)",
                backgroundSize: "100%",
                backgroundPosition: "center 70%",
              }}
              data-content="HOME"
              id="sidenav_home"
            >
              <div className="container">
                <div className="row align-items-center banner-inner ">
                  <div className="col-md-6">
                    <div className="content-blog">
                      <div className="banner-content">
                        <h1
                          className=" title  m-b20"
                          style={{ width: "120%", color: "white" }}
                        >
                          THE ART OF <br />
                          PROPERTY DEVELOPMENT <br />
                        </h1>
                        {/* <Link to={"/contact-us-1"} className=" btn btn-primary">
                        Book A Site Visit
                      </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Main Slider End--> */}

            {/* Section-1  Start*/}
            <section
              className="amenities-area"
              style={{ maxWidth: "1440px", margin: "auto" }}
            >
              <Slider1 />
            </section>
            {/* Section-1  End*/}

            {/* Section-2  Start*/}
            <section className="content-inner-2" id="sidenav_specifications">
              <div className="container">
                <div className="section-head head-col">
                  <h2 className="title" style={{ width: "70%" }}>
                    AWARD-WINNING
                  </h2>
                  <p>
                    Our projects have garnered prestigious recognition,
                    underscoring our commitment to excellence and innovation in
                    both corporate and residential spaces.
                  </p>
                </div>
              </div>
              <section className="content-inner exhibition-bx">
                <ExhibitionSection />
              </section>
            </section>
            {/* Section-2 End */}

            {/* Section-3 */}

            <section className="content-inner about-box" id="sidenav_aboutUs">
              <div
                className="about-bg"
                style={{
                  marginTop: "20%",
                  height: "70%",
                  width: "100%",
                  marginLeft: "5%",
                }}
              ></div>{" "}
              <div className="container">
                <div className="row">
                  <div
                    className="col-md-7 col-lg-6"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      flex: "0 0 100%",
                    }}
                  >
                    <div className="section-head">
                      <h2 className="title">OUR PHILOSOPHY</h2>
                      <div className="dlab-separator bg-primary "></div>
                      <h4 className="mb-4">
                        Redefining Luxury, Raising Standards{" "}
                      </h4>
                      <p className="pargrahpnormal" style={{ width: "100%" }}>
                        Every ÜBER development is a testament to our commitment
                        to luxury and sophistication. We meticulously design and
                        construct each property to provide an unparalleled
                        customer experience, blending cutting-edge architecture
                        with timeless elegance. Our passion for excellence
                        drives us to push boundaries, ensuring that every new
                        project sets a higher standard in the industry.
                      </p>
                      <p style={{}}>
                        <b>Innovation:</b> Embrace the latest technologies and
                        design trends to create properties that are both
                        functional and aesthetically pleasing.
                      </p>
                      <p style={{}}>
                        <b>Quality:</b> Ensure superior craftsmanship with
                        meticulous attention to detail, delivering perfection in
                        every project.
                      </p>
                      <p style={{}}>
                        <b>Sustainability:</b> Integrate eco-friendly practices
                        into developments to create sustainable,
                        energy-efficient homes for a better future.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="content-inner-2"
              style={{ paddingTop: "0px" }}
              id="sidenav_ourServices"
            >
              <div className="container">
                <div className="row align-items-end section-head">
                  <div
                    className="col-md-6"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      flex: "0 0 100%",
                    }}
                  >
                    <h2 className="title">OUR DEVELOPMENTS</h2>
                    <div className="dlab-separator bg-primary"></div>
                    <h4 className="mb-4">Explore our portfolio</h4>
                    <p className="mb-3 mb-md-0">
                      Discover how each of our developments exemplifies the
                      pinnacle of luxury and innovation. From visionary designs
                      to flawless execution, our projects set the standard.
                    </p>
                  </div>
                </div>
              </div>
              <div className="service-area">
                <div className="row spno service-row">
                  <div
                    className="col "
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                    }}
                  >
                    <a href="/hyde-park-corner" className="service-box">
                      <div className="media">
                        <img
                          src="https://cdn.uberpd.co.za/img/projects/hyde-park/hyde-park.jpg"
                          alt=""
                        />
                        <div
                          className="gradient-overlay"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage:
                              "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                            backgroundSize: "100% 100%",
                            backgroundBlendMode: "multiply",
                          }}
                        ></div>
                        <div
                          style={{
                            textAlign: "center",
                            color: "white",
                            fontSize: 20,
                            fontWeight: "900",
                            textTransform: "uppercase",
                            letterSpacing: 1,
                            wordWrap: "break-word",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: "10%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                          }}
                        >
                          HYDE PARK{" "}
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className="col"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                    }}
                  >
                    <a href="/alpha-building" className="service-box">
                      <div className="media">
                        <img
                          src="https://cdn.uberpd.co.za/img/nicol-corner-ium-building.jpg"
                          alt=""
                        />
                        <div
                          className="gradient-overlay"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage:
                              "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                            backgroundSize: "100% 100%",
                            backgroundBlendMode: "multiply",
                          }}
                        ></div>
                        <div
                          style={{
                            textAlign: "center",
                            color: "white",
                            fontSize: 20,
                            fontWeight: "900",
                            textTransform: "uppercase",
                            letterSpacing: 1,
                            wordWrap: "break-word",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: "10%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                          }}
                        >
                          Alpha Buidling{" "}
                        </div>
                      </div>
                    </a>
                  </div>

                  <div
                    className="col "
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                    }}
                  >
                    <a href="/ardenwold-manor" className="service-box">
                      <div className="media">
                        <img
                          src="https://cdn.uberpd.co.za/img/homepage/ardenwold.png"
                          alt=""
                        />
                        <div
                          className="gradient-overlay"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage:
                              "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                            backgroundSize: "100% 100%",
                            backgroundBlendMode: "multiply",
                          }}
                        ></div>
                        <div
                          style={{
                            textAlign: "center",
                            color: "white",
                            fontSize: 20,
                            fontWeight: "900",
                            textTransform: "uppercase",
                            letterSpacing: 1,
                            wordWrap: "break-word",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: "10%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                          }}
                        >
                          ARDENWOLD MANOR{" "}
                        </div>
                      </div>
                    </a>
                  </div>

                  <div
                    className="col "
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                    }}
                  >
                    <a href="/audi-sandton" className="service-box">
                      <div className="media">
                        <img
                          src="https://cdn.uberpd.co.za/img/projects/audi-sandton/Header.jpg"
                          alt=""
                        />
                        <div
                          className="gradient-overlay"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage:
                              "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                            backgroundSize: "100% 100%",
                            backgroundBlendMode: "multiply",
                          }}
                        ></div>
                        <div
                          style={{
                            textAlign: "center",
                            color: "white",
                            fontSize: 20,
                            fontWeight: "900",
                            textTransform: "uppercase",
                            letterSpacing: 1,
                            wordWrap: "break-word",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: "10%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                          }}
                        >
                          Audi Sandton{" "}
                        </div>
                      </div>
                    </a>
                  </div>

                  <div
                    className="col "
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                    }}
                  >
                    <a href="/bradford-road-2" className="service-box">
                      <div className="media">
                        <img
                          src="https://cdn.uberpd.co.za/img/projects/bradford-road-2/bradford-road (2).jpg"
                          alt=""
                        />
                        <div
                          className="gradient-overlay"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage:
                              "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                            backgroundSize: "100% 100%",
                            backgroundBlendMode: "multiply",
                          }}
                        ></div>
                        <div
                          style={{
                            textAlign: "center",
                            color: "white",
                            fontSize: 20,
                            fontWeight: "900",
                            textTransform: "uppercase",
                            letterSpacing: 1,
                            wordWrap: "break-word",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: "10%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                          }}
                        >
                          BRADFORD ROAD PHASE 2{" "}
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className="col "
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                    }}
                  >
                    <a href="/bradford-road" className="service-box">
                      <div className="media">
                        <img
                          src="https://cdn.uberpd.co.za/img/projects/BRADFORD-ROAD/bradford-road-1-header.jpg"
                          alt=""
                        />
                        <div
                          className="gradient-overlay"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage:
                              "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                            backgroundSize: "100% 100%",
                            backgroundBlendMode: "multiply",
                          }}
                        ></div>
                        <div
                          style={{
                            textAlign: "center",
                            color: "white",
                            fontSize: 20,
                            fontWeight: "900",
                            textTransform: "uppercase",
                            letterSpacing: 1,
                            wordWrap: "break-word",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: "10%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                          }}
                        >
                          BRADFORD ROAD PHASE 1{" "}
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col ">
                    <a href="/mont-blanc-height" className="service-box">
                      <div className="media">
                        <img
                          src="https://cdn.uberpd.co.za/img/projects/MONT-BLANC-HEIGHTS/299290859_150028967707521_674767665596506959_n-export.jpg"
                          alt=""
                        />
                        <div
                          className="gradient-overlay"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage:
                              "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                            backgroundSize: "100% 100%",
                            backgroundBlendMode: "multiply",
                          }}
                        ></div>
                        <div
                          style={{
                            textAlign: "center",
                            color: "white",
                            fontSize: 20,
                            fontWeight: "900",
                            textTransform: "uppercase",
                            letterSpacing: 1,
                            wordWrap: "break-word",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: "10%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                          }}
                        >
                          MONT BLANC HEIGHTS
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col">
                    <a href="/mont-saint-michel" className="service-box">
                      <div className="media">
                        <img
                          src="https://cdn.uberpd.co.za/img/projects/Mont-saint-michel/new/Font-shot.jpg"
                          alt=""
                        />
                        <div
                          className="gradient-overlay"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage:
                              "linear-gradient(180deg, rgba(0, 0, 0, 0) 38%, black 100%)",
                            backgroundSize: "100% 100%",
                            backgroundBlendMode: "multiply",
                          }}
                        ></div>
                        <div
                          style={{
                            textAlign: "center",
                            color: "white",
                            fontSize: 20,
                            fontWeight: "900",
                            textTransform: "uppercase",
                            letterSpacing: 1,
                            wordWrap: "break-word",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: "10%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                          }}
                        >
                          MONT SAINT MICHEL{" "}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      }
    </>
  );
};

export default Index1;
